<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'

export default {
  name: 'projectPage',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmShowSpecial/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'showName',
          title: '专题名称',
          align: 'left',
        },
        {
          dataIndex: 'showUrl',
          title: '专题地址',
          align: 'left',
          width: '40%',
        },
        {
          title: '模式',
          dataIndex: 'showSpecialType',
          customRender: function (text, records, index, h) {
            return text == '1' ? '富文本自定义' : '专题页模版'
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                display: records.showSpecialType == '0',
                name: 'Banner管理',
                onClick: () => {
                  this.$router.push(`/marketingCenter/indexBanner?busId=${records.id}`)
                },
              },
              {
                display: records.showSpecialType == '0',
                name: '分类管理',
                onClick: () => {
                  this.$router.push(`/marketingCenter/projectPageType?showId=${records.id}`)
                },
              },
              {
                display: true,
                name: '编辑',
                onClick: () => {
                  this.$router.push(`/marketingCenter/projectPage/detail?id=${records.id}`)
                },
              },
              {
                name: '删除',
                type: 'pop',
                display: true,
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/farmShowSpecial',
                    params: {
                      id: records.id,
                    },
                  })
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '专题名称',
              key: 'showName',
              labelAlign: 'right',
              type: 'input',
              display: true,
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '选择模式',
              key: 'showSpecialType',
              type: 'radio',
              display: true,
              typeData: [
                {
                  name: '专题页模版',
                  value: '0',
                },
                {
                  name: '富文本自定义',
                  value: '1',
                },
              ],
            },
            {
              name: '联系电话',
              key: 'linkPhone',
              type: 'input',
              display: formData.showSpecialType == '1',
            },
            {
              type: 'rich',
              key: 'richText',
              name: '富文本内容',
              display: formData.showSpecialType == '1',
            },
            {
              name: '底纹',
              type: 'iconUpload',
              key: 'a1',
              display: formData.showSpecialType == '0',
              typeData: [
                {
                  maxLength: 1,
                  key: 'backgroundImg',
                  desc: '宽<750px 图片平铺 <br/> @2x  xhdpi',
                },
              ],
            },
            {
              name: 'Banner图',
              type: 'iconUpload',
              key: 'a1',
              display: formData.showSpecialType == '0',
              typeData: [
                {
                  maxLength: 1,
                  key: 'bannerImg',
                  desc: '宽度750PX <br/> @2x  xhdpi',
                },
              ],
            },
            {
              name: '底部图',
              type: 'iconUpload',
              key: 'a1',
              display: formData.showSpecialType == '0',
              typeData: [
                {
                  maxLength: 1,
                  key: 'bottomImg',
                  desc: '宽度750PX <br/> @2x  xhdpi',
                },
              ],
            },
            {
              name: '返回图标',
              type: 'iconUpload',
              key: 'a1',
              display: formData.showSpecialType == '0',
              typeData: [
                {
                  maxLength: 1,
                  key: 'backImg',
                  desc: '宽度750PX <br/> @2x  xhdpi',
                },
              ],
            },
            {
              name: 'Banner图高度(px)',
              key: 'bannerHeight',
              labelAlign: 'right',
              type: 'input',
              display: formData.showSpecialType == '0',
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '专题模板',
              key: 'templateId',
              labelAlign: 'right',
              type: 'actionUploadTemplate',
              is_show: true,
              value: formData.templateId,
              display: formData.showSpecialType == '0',
              rules: [
                {
                  required: true,
                  type: 'array',
                },
              ],
            },
          ].filter((e) => e.display),
        },
      ]
      return form
    },
    handlePopup(dataSource = {}) {
      let that = this
      apiTool.showModal({
        title: '设置',
        width: '1000px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(dataSource, formData),
          form: dataSource,
        },
        success: ({ data, setHidden }) => {
          let { templateId, showSpecialType } = data
          if (showSpecialType == '1') {
            api.command[data.id ? 'edit' : 'create']
              .call(that, {
                url: '/farmShowSpecial',
                params: {
                  ...data,
                },
              })
              .then(setHidden)
            return
          }
          let aVal = templateId.find((f) => f.select === true)?.id || ''
          if (!data.showName && !data.bannerHeight && !aVal) {
            this.$message.error('请填写专题名称/Banner图高度/专题模板')
            return
          } else if (/^[0-9]*$/.test(data.bannerHeight) === false) {
            this.$message.error('Banner图高度必须为数字')
            return
          } else {
            api.command[data.id ? 'edit' : 'create']
              .call(that, {
                url: '/farmShowSpecial',
                params: {
                  ...data,
                  templateId: aVal,
                },
              })
              .then(setHidden)
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            debugger
            this.$router.push('/marketingCenter/projectPage/detail')
          },
        },
        {
          name: '模板管理',
          onClick: () => {
            this.$router.push({
              path: '/marketingCenter/indexTemplate',
            })
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
